*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body, html{     
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;  
}
.container-fluid{
  position: relative;
}
.user_loader{
  margin: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: absolute;
  transition: 400ms all;
}
.center{
  margin: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(2, 2, 2, 0.415);
  position: fixed;
  top: 0;
  left: 0;
  transition: 400ms all;
  color: white;
}

@media(min-width: 700px){
  #post_feed{
    display: none;
  }
}
.MuiAccordionSummary-content.Mui-expanded{
  margin-bottom: 6px !important;
}