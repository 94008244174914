.main-body{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #21D4FD;
    background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);

}
.inner-body{
    width: 70%;
    height: 80%;
    background-color: white;
    border-radius: 0.3rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    display: flex;
}
.inner-body .left{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column;
    gap: 20px; */
}
/* .inner-body .left img{
    width: 95%;
    height: auto;
} */
/* .lowerDiv{
    margin-top: 20px;
    padding: 0.7rem;
    text-align: center;
    border-radius: 1rem;
    width: 70%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 10px 20px -10px;
}
.lowerDiv h4{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    color: #2167fd;

}
.lowerDiv h5{
    font-family: Helvetica, sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    color: #000000a7;
    text-transform: lowercase;
} */
.inner-body .right{
    width: 50%;
    height: 100%;
    background-color: aliceblue;
    border-radius: 0rem 0.3rem 0.3rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;

}
.form{
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 60%;
}
.form input{
    margin-bottom: 0.2rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border-radius: 1.2rem;
    border: none;
    outline: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.form button{
    border: none;
    padding: 0.5rem;
    border-radius: 1.2rem;
    background-color: #2167fd;
    color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
@media(max-width: 700px){
    .inner-body{
        width: 90%;
        height: 90%;
        display: block;
    }
    .inner-body .left{
        width: 100%;
        height: 30%;
    }
    .inner-body .right{
        width: 100%;
        height: 70%;
    }
    .form{
        width: 90%;
    }
   
}

