.main_container{
    margin: 50px auto;
    height: 70vh;
    width: 80%;
    border-radius: 5px;
    background-color: white;
    padding: 1rem;
    overflow-y: scroll;
}
.action_item{
    width: 100%;
    border-radius: 5px;
    margin: 5px 0px 5px 0px;
    cursor: pointer;
    background-color: whitesmoke;
    padding: 2px;
}
.action_item:hover{
    background-color: rgb(238, 238, 238);
}

.main_container .header{
    height: 30%;
    background: linear-gradient(to right, #467df5, #4d4dd3);
}
.main_container .form_section{
    height: 70%;

}
.form_section .download_panel{
    height: 50%;
    width: 90%;
    background-color: whitesmoke;
    border-radius: 5px;
    
}