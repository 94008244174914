.upload-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.upload-body{
  height: 80%;
  width: 80%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.6rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.upload-body .left{
  width: 100%;
  height: 100%;

}

.upload-body .title h3{
  font-weight: 500;
  font-size: 1.3rem;
  padding: 1rem;
}
.upload-body .left .song-list{
  max-height: 65vh;
  overflow-y: scroll;
  padding: 1rem;
  width: inherit;
}
.upload-body .left .song-list li{
  list-style: none;

  font-weight: 600;
  margin-bottom: 1rem;
  padding: 1rem 0rem;
  width: inherit;
}
.upload-body .left .song-list li a{
  border-radius: 1rem;
  background-color: rgb(237, 237, 237);
  padding: 1rem;
  text-decoration: none;
  width: inherit;
}
.upload-body .right{
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

/* scrollbar  */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.form_box{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 2rem;
}
.form_container {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 4rem;
  width: 60%;
}
.display-container{
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 30%;
  height: fit-content;

}
.red{
  color: red;
}

.crbt-box{
padding: 1rem;
background-color: #ddffee;
border-radius: 0.7rem;
}
.status-box{
padding: 8px;
background-color: rgb(255, 255, 255);
border-radius: 0.5rem;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
display: flex;
justify-content: space-between;
margin-bottom: 1rem;
}
.status-box span{
color: #1cc88a;
font-size: 1.3rem;
}


/* Song Details Modal */

.modal-body img{
  width: 100%;
  height: auto;
}
.spinner{
  text-align: center;
}

.mid_loader_user{
  position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    background-color: rgba(2, 2, 2, 0.429);
}
.accordion-body{
  padding: 0;
}
.accordion{
  margin-bottom: 5px;
}

.accordion-button{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
}






@media(max-width:700px){
  .upload-body{
    width: 100%;
    max-height: 800px;
  }
  .upload-body .left .song-list {
    max-height: 500px;
  }
  .upload-body .left{
    max-height: 700px;
  }
  .accordion-button{
    display: block;
  }
  .form_container{
    width: 100%;
  }
  .form_box{
    flex-direction: column-reverse;
    gap: 1rem;
  }
  .display-container{
    width: 100%;
    height: fit-content;

  }
  
}