
.background{
    background: linear-gradient(to top, #467df5, #4d4dd3);
}
.sidebar .nav-item .nav-link[data-toggle=collapse].collapsed::after{
    display: none;
}
.sidebar-dark .nav-item .nav-link[data-toggle=collapse]::after{
    display: none;
}

@media(max-width: 700px){
    #arrowKey{
        visibility: hidden;
    }
    .background .nav-link{
        display: block !important;
    }
}