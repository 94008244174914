.search_box{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.input_row{
    border-radius: 1rem;
    width: 100%;
    position: relative;
}
.input_row input{
    width: 100%;
    outline: none;
    border: none;
    padding: 5px;
    border-radius: 16px;
    padding-left: 20px;
}
.input_row .saerch_button{
    position: absolute;
    top: 0;
    right: 7px;
    font-size: 20px;
    cursor: pointer;
   
}

/* CHange Password */

.change_password{
    background-color: rgb(28, 28, 28);
    border-radius: 5px;
    height: fit-content;
    width: 100%;
    margin: 1rem 0rem 1rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
.admin_icon{
    color: rgb(55, 55, 254);
    font-size: 4rem;
}
.label_section{
    padding: 1rem;
    background-color: rgb(28, 28, 28);
    border-radius: 5px;
    margin: 1rem 0rem 1rem 0rem;
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: url("../../../asset/images/label_header.png");
}
.label_section h2{
    color: rgb(86, 86, 255);
    padding: 0;
    margin: 0;
}
.label_section h4{
    padding: 0;
    margin: 0;
}
.store_control{
    background-color: rgb(28, 28, 28);
    border-radius: 5px;
    height: fit-content;
    width: 100%;
    margin: 1rem 0rem 1rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.album_list{
    background-color: rgb(28, 28, 28);
    border-radius: 5px;
    height: fit-content;
    width: 100%;
    margin: 1rem 0rem 1rem 0rem;
    padding: 1rem;
}
.middle{
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}