.container_box{
    max-width: 50%;
    margin: 2rem auto;
    padding: 1rem;
    border-radius: 5px;
}
.container_box input:focus{
    background-color: rgb(47, 47, 47);
    border: 1px solid #4e73df;
}
.container_box input{
    background-color: rgb(25, 25, 25);
    border: 1px solid rgba(128, 128, 128, 0.177);
}
.container_box select{
    background-color: rgb(25, 25, 25);
    border: 1px solid rgba(128, 128, 128, 0.177);
}