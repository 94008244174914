.support_footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    border-top: 0.5px solid #ababab8a;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}