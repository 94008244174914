.state_container{
    height: 100%;
    width: 100%;
    background-color: transparent;
    display: flex; 
    flex-direction: column;
    overflow-y: hidden;
}
.state_container .upper{
    width: 100%;
    max-height: 50%;
    margin-bottom: 2rem;
    overflow: auto;
}
.state_container .lower{
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.state_container .lower .left{
    width: 50%;
    height: 100%;
}
.state_container .lower .right{
    width: 50%;
    height: 100%;
}

.xl_container{
    margin: 50px auto;
    height: 70vh;
    width: 80%;
    border-radius: 5px;
    background-color: white;
    padding: 1rem;
    overflow-y: scroll;
    
}
.xl_container .header{  
    height: 30%;
    background: linear-gradient(to right, #467df5, #4d4dd3);
    padding: 1rem;
}
.form_section{
    margin-top: 1rem;
}
.download_panel {
    padding: 0.5rem;
}

@media(max-width:700px){
    .state_container{
        height: fit-content;
        width: 100%;
    }
    .state_container .upper{
        width: 100%;
        margin-bottom: 2rem;
        overflow: scroll;
        max-height: fit-content;
    }
    .state_container .lower{
        width: 100%;
        display: block;
        height: fit-content;
    }
    .state_container .lower .left{
        width: 100%;
        height: 100%;
    }
    .state_container .lower .right{
        width: 100%;
        height: 100%;
    }
    #apexchartshpghq1j4{
        margin: 0;
    }
    .form_section{
        margin-top: 1rem;
    }
    .download_panel {
        padding: 0.5rem;
        text-align: center;
    }
}