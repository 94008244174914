.release_details_container{
    margin: 0px auto;
    width: 70%;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-radius: 5px;
    background-color: rgb(38, 38, 38);
    color: rgb(229, 229, 229);

}
.release_details_container input:focus{
    background-color: rgb(47, 47, 47);
    border: 1px solid #4e73df;
}
.release_details_container textarea:focus{
    background-color: rgb(47, 47, 47);
    border: 1px solid #4e73df;
}
.release_details_container input{
    background-color: rgb(25, 25, 25);
    border: 1px solid rgba(128, 128, 128, 0.177);
}
.release_details_container textarea{
    background-color: rgb(25, 25, 25);
    border: 1px solid rgba(128, 128, 128, 0.177);
    
}

.nav-pills .nav-link {
    background: #1c1c1c;
    margin-bottom: 5px;
}
.release_item{
    padding: 1rem;
    background-color: rgb(24, 24, 24);
    border-radius: 5px;
    width: 90%;
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}
.final_submit_for_release{
    width: 90%;
    margin: 1rem auto;
}
.circle_status{
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: rgb(255, 225, 0);
}
.right_div_of_release{
    display: flex;
    align-items: center;
}
.status_div{
    height: 100%;
}


