.modal-body{
    font-size: 0.5rem;
}
.main{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.upper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lower{
    width: 100%;
}
.upper .left{
    width: 30%;
}
.upper .left img{
    width: 100%;
    height: auto;
}
.upper .right{
    width: 65%;
}
.upper .right h2{
    font-weight: 800;
    color: rgb(52, 52, 52);
}
.upper .right p{
    font-size: 1rem;
}

@media (max-width: 600px){
    .upper{
        display: block;
    }
    .upper .left{
        width: 100%;
        margin-bottom: 1rem;
    }
    .upper .left img{
        border-radius: 5px;
        width: 100%;
        height: auto;
    }
    .upper .right{
        width: 100%;
    }
}
