.container{
    width: 100%;
    
}
.released_album{
    height: fit-content;
    padding: 1rem;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 20%, #FFCC70 60%, #07070700 80%);
    /* background: linear-gradient(90deg, rgba(0, 143, 251, 0.85) 25%, rgba(255, 255, 255, 0) 83.07%); */
    margin-bottom: 0.5rem;
    border-radius: 7px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

@media(max-width: 700px){
    .released_album{
        background-color: #4158D0;
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

    }
}

