.MuiDataGrid-row:nth-child(even) {
    /* background-color: rgb(245, 245, 245); */
    color: white;
    background-color: rgb(27, 27, 27);
}
.MuiDataGrid-row:nth-child(odd) {
    /* background-color: rgb(245, 245, 245); */
    color: white;
    background-color: black;
}

.css-f3jnds-MuiDataGrid-columnHeaders {
    color: #8799fb;
    background-color: rgb(29, 29, 29);
}
.css-17jjc08-MuiDataGrid-footerContainer{
    background-color: #cbcbcb;
}
.css-r11z79-MuiDataGrid-root{
    border: none !important;
}
.css-f3jnds-MuiDataGrid-columnHeaders{
    border-bottom: none !important;
}
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cell{
    width: 100%;
    border-bottom: none !important;
}
/* header bar color */
.css-r11z79-MuiDataGrid-root .MuiDataGrid-columnSeparator{ 
    color: #8799fb !important;
}
.css-f3jnds-MuiDataGrid-columnHeaders  .MuiButtonBase-root{
    color: #9f9f9f !important;
}

.sub_details_main{
    display: flex;
    justify-content: flex-start;
    position: relative;
}
.sub_details_main .left{
    width: 70%;
    height: 76vh;
    overflow-y: scroll;
    padding: 1rem;
}
.sub_details_main .right{
    width: 20%;
    padding: 1rem;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 100px;
    background-color: #0a0a0a;
}
.sub_details_main .left input{
    background-color: #0a0a0a;
    border: none;
}
.sub_details_main .left textarea{
    background-color: #0a0a0a;
    border: none;
}
.sub_details_main .left label{
    color: white;
}
.song_details_list{
    background-color: #D9D9D9;
    color: black;
    border-radius: 8px;
    text-align: center;
    padding: 3px;
    margin-top: 10px;
    width: 100%;
}
.sub_details_main .right textarea{
    background-color: #252525;
    border: none;
}
.sub_details_main .right a{
    text-decoration: none;
    width: 100%;
    color: black;
}

.sub_details_main .right .button-group{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
}
.myTextarea{
    width: 100%;
    height: 200px;
    padding: 5px;
    border-radius: 4px;
    color: white;
    border: none;
}
.mid_loader{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    background-color: rgba(2, 2, 2, 0.429);
}
.edit_btn_div{
    width: 100%;
    text-align: end;
    font-size: 2rem;
    
}
.edit_btn_div button{
    background-color: transparent;
    border: none;
    outline: none;
    color: #8799fb;
    margin-right: 3rem;
}
.song input{
    background-color: #0a0a0a !important;
    border: none;
}
.song textarea{
    background-color: #0a0a0a !important;
    border: none;
}
.makeStyles-paper-2{
    background: #2d2d2d;
    color: #acacac;
}
.makeStyles-paper-1{
    background: #2d2d2d;
    color: #acacac;
}


.song input:focus{
    background-color: rgb(47, 47, 47);
    border: 1px solid #4e73df;
}
.song input{
    background-color: rgb(25, 25, 25);
    border: 1px solid rgba(128, 128, 128, 0.177);
}
.song select{
    color: #595959;
    background-color: rgb(9 9 9);
    border: 1px solid rgba(128, 128, 128, 0.177);
}