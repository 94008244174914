.admin_outlet{
    padding: 100px 4rem 40px 4rem;
    background-color: rgb(22, 22, 22);
    min-height: 100vh;
    width: 100%;
}
.navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(29, 29, 29);
    padding: 10px;
}
.navbar-custom{
    background-color: #0a0a0a;
    color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.navbar .left{
    width: 50%;
}
.navbar .left ul{
    width: 100%;
    display: flex;
    list-style: none;
}
.navbar .left li{
    margin-left: 1rem;
}
.nav_right{
    position: absolute;
    top: 0;
    right: 0;
}