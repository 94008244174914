.container_main {
    width: 100%;
}

.card-color {
    background-color: rgb(31, 31, 31);
    border: none;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.card_icon {
    font-size: 2rem;
}

.test {
    color: #f6c23e57;
}

.tool_box {
    /* background-color: rgb(31, 31, 31); */
    height: 400px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

.upload_box {
    height: 100%;
    border-radius: 10px;
    width: 65%;
    background-color: rgb(29, 29, 29);
    display: flex;
    flex-direction: column;
}
.verify_box {
    height: 100%;
    border-radius: 10px;
    width: 30%;
    background-color: rgb(195, 195, 195);
    display: flex;
    flex-direction: column;
}

.verify_box .upper,
.upload_box .upper {
    height: 10%;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: rgb(60, 60, 60);
    color: white;
    padding-left: 0.5rem;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
}

.verify_box .item_list {
    height: 87%;
    width: 100%;
    padding: 0.5rem;
    overflow-y: scroll;
}

.item_list::-webkit-scrollbar {
    width: 5px;
}

.verify_box .title {
    position: sticky;
    top: 0;
    background-color: aliceblue;
}

.verify_item {
    padding: 0.5rem;
    width: 100%;
    border-radius: 5px;
    background-color: rgb(60, 60, 60);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    margin: 0.5rem 0rem 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.verify_item .icon {

    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
}

.create_post {
    font-size: 2rem;
    position: fixed;
    right: 4rem;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    border-radius: 10px;
    padding: 1rem;
    background-color: rgb(31, 31, 31);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}